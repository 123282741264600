/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /** custom **/
  --ion-font-family: "Segoe UI", "Noto Sans";
  --bw2-darksoft: #384148;
  --bgbw2: #2a5780;
  /**
  ion-backdrop{
      display: block !important;
      opacity: 0.7 !important;
  }
  ion-backdrop.menu-backdrop.backdrop-no-tappable.hydrated.show-backdrop{
      opacity: 0.7 !important;
      display: block !important;
  } **/
}
:root .ion-color-avatar {
  --ion-color-base: #2a5780;
  --ion-color-base-rgb: 34, 73, 112;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 34, 73, 112;
  --ion-color-shade: #224970;
  --ion-color-tint: #224970;
  border-bottom: 1px solid #224466 !important;
}
:root .ion-color-bw2toolbar {
  --ion-color-base: #436e98;
  --ion-color-base-rgb: 67, 110, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 67, 110, 152;
  --ion-color-shade: #436e98;
  --ion-color-tint: #436e98;
}
:root ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --inner-border-width: 0px;
}
:root ion-toolbar {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --min-height: 52px;
}
:root .mobile-mode ion-toolbar {
  --min-height: 55px;
}
:root ion-toolbar.menu-toolbar.ion-color.ion-color-avatar.hydrated {
  --padding-bottom: 10px;
}
:root ion-menu {
  --max-width: 200px;
  z-index: 11;
}
:root ion-list {
  --ion-item-background: #f2f2f2;
  contain: unset;
}